import React from "react";
import { safeContent } from "../../utils";

function Testimonial(props) {
  const { image, content, name, job, color, lead } = props;

  return (
    <blockquote
      className={`testimonial ${image ? "" : "testimonial-icon"} text-center ${
        "white" === color ? "text-white" : ""
      }`}
    >
      {/* {image ? <img src={image} loading="lazy" width={"50px"} height={"50px"} alt="user" /> : ""} */}
      {lead ? <p style={{color: "#FFFFFF"}} className="lead">{lead}</p> : ""}
      <p style={{color: "#FFFFFF"}} dangerouslySetInnerHTML={safeContent(content)}></p>
      <cite className="text-white">
        <b style={{color: "#FFFFFF"}}>
          {" "}
          {name}
          <span>{job}</span>
        </b>
      </cite>
    </blockquote>
  );
}

export default React.memo(Testimonial);
