import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";

import slugify from "slugify";

import { GatsbyImage } from "gatsby-plugin-image";

export default function BannerGroup() {
  const { allFeatCategories } = useStaticQuery(
    graphql`
      {
        allFeatCategories {
          nodes {
            name
            seoMetaData {
              slug
            }
            itemCount
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  width: 315
                  height: 150
                  quality: 100
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    `
  );

  return (
    <div className="banner-group">
      <div className="container">
        <h2 className="text-primary text-center mt-6 mb-4">
          <span className="highlight-heading"> Featured Categories</span>
        </h2>
        <div className="container">
          <div className="row">
            {allFeatCategories.nodes.map((item, index) => (
              <div key={index} className="col-sm-6 col-md-4 col-lg-3">
                <div className="banner banner-overlay banner-featured__background">
                  <Link
                    aria-label={item.name}
                    to={`/collections/${slugify(
                      item.seoMetaData?.slug || item.name.toLowerCase()
                    )}/`}
                  >
                    {/* <div className="lazy-overlay bg-3"></div> */}

                    <div style={{ width: "100%" }}>
                      {item?.thumbnail && (
                        <GatsbyImage
                          image={item.thumbnail.childImageSharp.gatsbyImageData}
                          alt={item.name}
                        />
                      )}
                    </div>
                  </Link>

                  <div className="banner-content banner-content-bottom">
                    <h3 className="banner-title text-primary font-weight-bold">
                      {item.name && item.name.split(" ").length > 3
                        ? item.name.split(" ")[0] +
                          " " +
                          item.name.split(" ")[1] +
                          "\n" +
                          item.name.split(" ").splice(2).join(" ")
                        : item.name}
                    </h3>
                    <h4 className="banner-subtitle text-dark font-weight-normal">
                      Up to 50% off
                    </h4>
                    <Link
                      to={`/collections/${slugify(
                        item.seoMetaData?.slug || item.name.toLowerCase()
                      )}/`}
                      className="btn btn-outline-darker btn-outline-darker__bgwhite banner-link"
                      aria-labelledby={`shop-${index}`}
                    >
                      <span id={`shop-${index}`}>Shop</span>{" "}
                      <i className="icon-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
