import React from "react";
import { Link } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function BannerGroup() {
  return (
    <div className="banner-group">
<h2 className="text-primary text-center mt-6 mb-4">
          <span className="highlight-heading"> Our Services</span>
        </h2>

      <div className="container ">
        <div className="row ">
          <div className="col-sm-6 col-md-6 col-lg-4">
            <div
              className="banner banner-overlay banner-height"
              style={{ backgroundColor: "#000" }}
            >
              {/* <Link to={`/collections/${slugify(item.name.toLowerCase())}`}> */}
              <div className=" banner-content-top text-center">
                <h3 className="banner-title pt-1 text-white">ONLINE STORE</h3>
              </div>

              <div className="d-flex flex-row justify-content-between">
                <div style={{ width: "100%",height:"110px" }}>
                  <LazyLoadImage
                    alt="banner"
                    src={`/assets/images/online-store.jpg`}
                    threshold={200}
                    height={245}
                    width={100}
                    effect="blur"
                  />
                  {/* {item?.thumbnail?.local && (
                          <Img
                            backgroundColor={"transparent"}
                            fluid={item.thumbnail.childImageSharp.fluid}
                            alt=""
                          />
                        )} */}
                </div>
                <div
                  style={{
                    backgroundColor: "#C02425",
                   height:'110px'
                  }}
                  className="d-flex flex-column banner-content-right justify-content-center align-items-center   ml-4"
                >
                  <h3 className="banner-title    text-center   p-2 text-white">
                    Order online and browse our large selection of bargains!
                  </h3>
                </div>
              </div>
              {/* </Link> */}

              <div
                className=" banner-content-bottom m-2"
                style={{ position: "absolute", bottom: "0" }}
              >
                <Link
                  to={`/allcollection/`}
                  className="btn btn-sm text-white banner-link"
                >
                  Learn More<i className="icon-long-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4">
            <div
              className="banner banner-overlay banner-height"
              style={{ backgroundColor: "#000" }}
            >
              {/* <Link to={`/collections/${slugify(item.name.toLowerCase())}`}> */}
              <div className=" banner-content-top text-center">
                <h3 className="banner-title pt-1 text-white">RETAIL STORE</h3>
              </div>

              <div className="d-flex flex-row justify-content-between">
                <div style={{ width: "100%",height:"110px" }}>
                  <LazyLoadImage
                    alt="banner"
                    src={`/assets/images/retail-store.jpg`}
                    threshold={200}
                    height={245}
                    width={100}
                    effect="blur"
                  />
                </div>
                <div
                  style={{
                    backgroundColor: "#C02425",
                    height:'110px'
                  }}
                  className="d-flex flex-column banner-content-right justify-content-center align-items-center   ml-4"
                >
                  <h3
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                    className="banner-title    text-center   p-2 text-white"
                  >
                    Wide Variety of Deeply Discounted Products up to 80% OFF
                    Retail
                  </h3>
                </div>
              </div>
              {/* </Link> */}

              <div
                className=" banner-content-bottom m-2"
                style={{ position: "absolute", bottom: "0" }}
              >
                <Link to={`/contact/`} className="btn btn-sm text-white banner-link">
                  Learn More<i className="icon-long-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4">
            <div
              className="banner banner-overlay banner-height"
              style={{ backgroundColor: "#000" }}
            >
              {/* <Link to={`/collections/${slugify(item.name.toLowerCase())}`}> */}
              <div className=" banner-content-top text-center">
                <h3 className="banner-title pt-1 text-white">WHOLESALE</h3>
              </div>

              <div className="d-flex flex-row justify-content-between">
                <div style={{ width: "100%",height:"110px" }}>
                  <LazyLoadImage
                    alt="banner"
                    src={`/assets/images/truckload-393w.png`}
                    threshold={200}
                    height={245}
                    width={100}
                    effect="blur"
                  />
                  {/* {item?.thumbnail?.local && (
                          <Img
                            backgroundColor={"transparent"}
                            fluid={item.thumbnail.childImageSharp.fluid}
                            alt=""
                          />
                        )} */}
                </div>
                <div
                  style={{
                    backgroundColor: "#C02425",
                    height:'110px'
                  }}
                  className="d-flex flex-column banner-content-right justify-content-center align-items-center   ml-4"
                >
                  <h3
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                    className="banner-title    text-center   p-2 text-white"
                  >
                    Truckloads and pallet sale available today
                  </h3>
                </div>
              </div>
              {/* </Link> */}

              <div
                className=" banner-content-bottom m-2"
                style={{ position: "absolute", bottom: "0" }}
              >
                <Link
                  to={`/wholesale/`}
                  className="btn btn-sm text-white banner-link"
                >
                  Learn More<i className="icon-long-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
