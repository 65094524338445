import React from "react";
import { Link } from "gatsby";
// import Img from "gatsby-image";
// import slugify from "slugify";

import { LazyLoadImage } from "react-lazy-load-image-component";

function banner(props) {
  return (
    <div className="container-fluid program-banner-container">
      <div className="row d-flex flex-row bg-gray justify-content-center">
        <div  style={{width: '56rem'}} className="d-flex align-items-center justify-content-center">
          <div style={{maxWidth: '55%'}} className='p-2'>
          <Link to="/productrequest/" aria-labelledby="request">
            <h3 className=" text-primary" id="request">Can't find what you need?</h3>
            <p className="banner-title">
              Consider filling out this simple form and we will notify you when
              we have what you are looking for!
            </p>
            </Link>
          </div>
        </div>
        <div>
        <Link to="/productrequest/" aria-label="request">
          <LazyLoadImage
            alt="banner"
            height={150}
            width={500}
            src={`/assets/images/wantedproducts1.png`}
            threshold={200}
            effect="opacity"
          />
          </Link>
        </div>


      </div>
    </div>
  );
}

export default React.memo(banner);
