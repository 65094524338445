import React, { Component } from "react";
import Slider from "react-slick";
import Testimonial from "../../components/features/testimonial";

const sliderProps = {
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  dots: true,
  arrows: false,
};
export default class Testimonials extends Component {
  render() {
    return (
      <div>
        <div className="about-testimonials  pt-6 pb-6">
          <div className="container">
            <h2 className="title text-white text-center mb-2">
              Happy Customers
            </h2>
            <Slider
              // style={{ backgroundColor: "white !important" }}
              {...sliderProps}
              slidesToShow={1}
              slidesToScroll={1}
            //   arrows={true}
            >
              <Testimonial
                // image={`/assets/images/testimonials/user-2.jpg`}
                content="“ Great quality and such a fun shopping experience. Excited to shop again.”"
                name="Julie F."
                job="Customer"
              />

              <Testimonial
                // image={`/assets/images/testimonials/user-2.jpg`}
                content="“ Love this store! Everyone is so nice! Awesome place! ”"
                name="Melissa H"
                job="Customer"
              />

              <Testimonial
                // image={`/assets/images/testimonials/user-2.jpg`}
                content="“Wow! I loved the amazing deals. The amazing products AND THE STAFF! what a great shopping experience. Thank you! We will be returning!!”"
                name="Brittany S"
                job="Customer"
              />
              <Testimonial
                // image={`/assets/images/testimonials/user-2.jpg`}
                content="“Great owners and great company”"
                name="Chante F"
                job="Customer"
              />
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
