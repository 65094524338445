import React from "react";
import { graphql } from "gatsby";
// import Custom Components
import HomeSlider from "../components/home/HomeSlider";
import Service from "../components/features/service";
import NewsletterModal from "../components/features/modal/newsletter-modal";
import FeaturedCategories from "../components/home/FeaturedCatgories";
import Services from "../components/home/services";
import Products from "../components/home/Products";
// import RecentlyViewed from "../components/home/RecentlyViewed";
import SubscriptionForm from "../components/common/SubscriptionForm";
import Seo from "../components/common/SEO";
import MainLayout from "../layouts/MainLayout";
// import TagLineBanner from "../components/home/TagLineBanner";

import ProgramBanner from "../components/home/ProgramBanner";

import Testimonials from "../components/home/Testimonials";

const Index = ({ data, location }) => {
  return (
    <>
      <MainLayout>
        <div className="main home-page">
          <Seo title="Owls" />
          {/* Home Banner */}
          <HomeSlider />
          {/* <TagLineBanner /> */}
          <div className="mb-3 mb-lg-5"></div>
          <Services />
          {/* <Banner /> */}
          <FeaturedCategories />

          <div className="mb-2"></div>
          <Products />
          <div className="mb-2"></div>
          <ProgramBanner />
          <Service />
          {/* <RecentlyViewed /> */}
        </div>
        <SubscriptionForm metadata={data.metadata} />
        <Testimonials />
        <NewsletterModal demo="4" metadata={data.metadata} />
      </MainLayout>
    </>
  );
};

export default Index;
export const query = graphql`
  query MyQuery {
    metadata {
      isKlaviyoConnected
    }
  }
`;
