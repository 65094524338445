import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image";

const HomeSlider = () => (
  <StaticQuery
    query={graphql`{
    allSliderImages {
    edges {
      node {
          url {childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
}
`}
    render={({ allSliderImages }) => (
      <section className="p-0">
        <Slider className="slide-1 home-slider">
          {allSliderImages.edges.map(({ node }, key) => (
            <div key={key}>
              {/* intro-slide */}
              <div
                className="home text-center intro-slide "
                style={{ position: `relative` }}
              >
                <GatsbyImage
                  image={node.url.childImageSharp.gatsbyImageData}
                  alt="home slider"
                  loading={key === 0 ? "eager" : "lazy"}
                  style={{ width: "100%", height: "100%" }}
                  imgStyle={{ objectFit: "cover" }} />
                <div className="container btn-container">
                  <div className="row justify-content-end">
                    <div className="col-auto col-sm-7 col-md-6 col-lg-5">
                      {/* btn btn-dark btn-outline-darker */}
                      <Link to={`/allcollection/`} tabIndex={-1} className="btn btn-primary ">
                        <span>Shop</span>
                        <i
                          aria-hidden="true"
                          className="icon-long-arrow-right"
                        ></i>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* {slide.isVisible && (
                                <div style={{position: 'absolute', top: 0,width: '100%', height: '100%' }}>
                                  <div  className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="slider-contain">
                                                <div>
                                                    {slide.subtitle && (<h4>{slide.subtitle}</h4>)}
                                                    {slide.title && (<h1>{slide.title}</h1>)}
                                                    {slide.url && (<Link to={slide.url} className="btn btn-solid">shop now</Link>)}
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              )} */}
              </div>
            </div>
          ))}
        </Slider>
      </section>
    )}
  />
);

export default HomeSlider;
