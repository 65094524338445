import React, { Component } from "react";
import { encode } from "../../utils";

class Newletter extends Component {
  constructor(props) {
    super(props);
    this.state = { status: "init", email: "", name: "" };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    this.setState({ status: "submitting" });
    if (this.props.metadata.isKlaviyoConnected) {
      let url = `${process.env.GATSBY_API_BASE_URL}/api/v1/gatsby/klaviyoAddForm`;

      const method = "post";
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
      };

      fetch(url, {
        method,
        headers,
        body: JSON.stringify({ email: this.state.email }),
      })
        .then((res) => {
          if (!res.ok) throw res;
          return res.json();
        })
        .then((res) => {
          this.setState({ status: "success", email: "", name: "" });
        })
        .catch((err) => {
          this.setState({ status: "failed" });
          console.log(err);
        });
    } else {
      let url = `${process.env.GATSBY_API_BASE_URL}/api/v1/marketplace/forms`;
      const method = "post";
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
      };
      fetch(url, {
        method,
        headers,
        body: JSON.stringify({
          firstName: this.state.name,
          email: this.state.email,
          newsLetterRequest: {
            isNewsLetter: true,
          },
        }),
      })
        .then((res) => {
          this.setState({ status: "success", email: "", name: "" });
          // console.log(res);
        })
        .catch((err) => {
          this.setState({ status: "failed" });
          console.log(err);
        });
    }
    e.preventDefault();
  };
  render() {
    return (
      <div
        className="cta bg-image bg-dark pt-2 pb-2 mb-0"
        // style={}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-10 col-md-8 col-lg-6">
              <div className="cta-heading text-center">
                <h3 className="cta-title text-white">
                  Subscribe to our Newsletter
                </h3>
                <p className="cta-desc text-white">
                  Subscribe to our newsletter for special
                  <span className="font-weight-normal">
                    discounts, promotions, and new inventory
                  </span>{" "}
                  updates that you won't want to miss.
                </p>
              </div>

              <form
                className="subscribe-form form-inline"
                name="subscribe"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                <input type="hidden" name="form-name" value="subscribe" />
                <input
                  type="hidden"
                  name="bot-field"
                  onChange={this.handleChange}
                />

                <div className="input-group ">
                  <input
                    type="email"
                    className="form-control  mb-2 mr-sm-2"
                    placeholder=" Email Address"
                    aria-label="Email Adress"
                    required
                    name="email"
                    onChange={this.handleChange}
                  />
                  <input
                    type="text"
                    name="name"
                    aria-label="name"
                    className="form-control  mb-2 mr-sm-2"
                    placeholder="Enter your name"
                    onChange={this.handleChange}
                  />
                  <div className="input-group  mb-2 mr-sm-2">
                    <button
                      className="btn btn-primary"
                      disabled={
                        this.state.status === "submitting" ||
                        this.state.status === "success"
                      }
                      type="submit"
                      aria-labelledby="subscribe"
                    >
                      <i className="fa fa-envelope"></i>
                      <span id="subscribe">Subscribe</span>
                      <i className="icon-long-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </form>
              {this.state.status === "success" && (
                <div className="text-success text-center">
                  <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                  You've successfully subscribed our Newsletter.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Newletter;
