import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import slugify from "slugify";

import { addToCart } from "../../../reducers/cart";
import { showQuickViewModal } from "../../../actions";

function ProductNine(props) {
  const { product, addToCart, showQuickView } = props;
  const [quantity] = useState(1);
  const addToCartHandler = () => {
    if (0 !== product.quantity)
      addToCart({ ...product, quantity, stock: product.quantity });
  };

  const quickViewHandler = () => {
    showQuickView(product);
  };
  return product ? (
    <div
      className="product product-11 text-center ml-3 "
      style={{ maxHeight: "550px", maxWidth: "250px" }}
    >
      <figure className="product-media" aria-labelledby={`image-${product._id}`}>
        {0 === product.quantity ? (
          <span className="product-label label-out">Sold Out</span>
        ) : (
          ""
        )}

        {/* {product.condition ? (
          <span className="product-label label-circle label-new">
            {product.condition}
          </span>
        ) : (
          ""
        )} */}

        {Number(product.oldPrice) - Number(product.price) > 0 && (
          <span className="product-label label-circle bg-primary text-white ">
            {(
              (100 * (Number(product.oldPrice) - Number(product.price))) /
              product.oldPrice
            ).toFixed(0)}
            % Off <br />
            {/* Off {` ($${(Number(product.oldPrice) - Number(product.price))})`} */}
          </span>
        )}
        <Link
        tabIndex={-1}
          to={`/products/${
            product.seoMetaData?.slug || slugify(product.title.toLowerCase())
          }/`}
        >
          <GatsbyImage
          id={`image-${product._id}`}
            image={product.image.childImageSharp.gatsbyImageData}
            alt={`image ${product.title}`}
            // style={{ height: "100%", width: "100%" }}
          />
        </Link>

        <div className="product-action-vertical">
          <button
            aria-label="Quick view"
            className="btn-product-icon btn-quickview"
            onClick={quickViewHandler}
            title="Quick view"
          ></button>
        </div>
      </figure>

      <div className="product-body">
        {/* style={{minHeight:"90px"}} */}
        <h3 className="product-title" >
          <Link
          tabIndex={-1}
            to={`/products/${
              product.seoMetaData?.slug || slugify(product.title.toLowerCase())
            }/`}
          >
          {product.title.slice(0, 25)} 
          </Link>
        </h3>

        {0 === product.quantity ? (
          <div className="product-price">
            <span className="out-price">
              $
              {Number(product.price).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        ) : 0 < product.oldPrice && product.oldPrice > product.price ? (
          <div className="product-price">
            <span className="new-price">
              $
              {Number(product.price).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
            <span className="old-price">
              $
              {Number(product.oldPrice).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        ) : (
          <div className="product-price">
            $
            {Number(product.price).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        )}
        <StaticQuery
          query={graphql`
            query ratingProduct {
              metadata {
                ratingsVisibility
              }
            }
          `}
          render={(data) =>
            data.metadata.ratingsVisibility ? (
              <div className="ratings-container">
                <div className="ratings">
                  <div
                    className="ratings-val"
                    style={{ width: product?.rating * 20 + "%" }}
                  ></div>
                </div>
                <span className="ratings-text">
                  ({product.rating?.toFixed(2) ?? 0} Reviews )
                </span>
              </div>
            ) : null
          }
        />
      </div>

      <div className="product-action">
        <button
          className="btn-product btn-cart"
          disabled={product.quantity === 0}
          onClick={addToCartHandler}
        >
          <span>add to cart</span>
        </button>
      </div>
    </div>
  ) : (
    ""
  );
}

function mapDispatchToProps(dispatch) {
  return {
    addToCart: (item) => dispatch(addToCart(item)),
    showQuickView: (item) => dispatch(showQuickViewModal(item)),
  };
}

export default connect(null, mapDispatchToProps)(ProductNine);
